import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { fireStore } from "../firesbase";

// Constants
import { NEW_SCENE } from "../constants/constants";

export const fetchMarbleScenes = async () => {
  try {
    const collectionRef = collection(
      fireStore,
      process.env.REACT_APP_FIREBASE_COLLECTION,
    );
    const querySnapshot = await getDocs(query(collectionRef, orderBy("title")));
    const marbleScenes = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    return marbleScenes;
  } catch (error) {
    console.error("Error fetching marble collection:", error);
  }
};

export const fetchMarbleScene = async (sceneId) => {
  try {
    const docRef = doc(
      fireStore,
      process.env.REACT_APP_FIREBASE_COLLECTION,
      sceneId,
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return { ...NEW_SCENE, title: sceneId, createdAt: new Date() };
    }
  } catch (error) {
    console.error("Error fetching document data:", error);
  }
};

export const addMarbleScene = async () => {
  try {
    const collectionRef = collection(
      fireStore,
      process.env.REACT_APP_FIREBASE_COLLECTION,
    );
    const newDocRef = doc(collectionRef);
    const newDocId = newDocRef.id;
    return newDocId;
  } catch (error) {
    console.error("Error adding marble:", error);
  }
};

export const deleteMarbleScene = async (sceneId) => {
  try {
    const docRef = doc(
      fireStore,
      process.env.REACT_APP_FIREBASE_COLLECTION,
      sceneId,
    );
    await deleteDoc(docRef);
  } catch (error) {
    console.error("Error deleting document:", error);
  }
};

export const updateMarbleScene = async (sceneId, scene) => {
  try {
    const docRef = doc(
      fireStore,
      process.env.REACT_APP_FIREBASE_COLLECTION,
      sceneId,
    );
    await setDoc(docRef, scene);
  } catch (error) {
    console.error("Error writing document:", error);
  }
};

// export const updateMarbleSceneToNewCollection = async (scenes) => {
//   scenes?.forEach(async (scene) => {
//     try {
//       const docRef = doc(
//         fireStore,
//         process.env.REACT_APP_FIREBASE_COLLECTION,
//         scene.id,
//       );
//       await setDoc(docRef, scene.data);
//     } catch (error) {
//       console.error("Error writing document:", error);
//     }
//   });
// };
